import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

let search = window.location.search;
let params = new URLSearchParams(search);
let foo = params.get('lang');
i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
	  lng:foo,
    fallbackLng: 'tw',
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
    //,
    //react: {
    //    useSuspense: false
    //}
  });

export default i18n;
