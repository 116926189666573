import "./instructions.css";
import React from 'react'
import { Link } from "react-router-dom";
import Collapsible from 'react-collapsible';
import imgIntructions1 from './img/Instruction1-2024-02.png';
import imgIntructions3 from './img/Instruction3.png';
import imgInstructionsStep1 from './img/WelcomePageStep1.png';
import imgInstructionsStep2 from './img/WelcomePageStep2.png';
import imgInstructionsStep3 from './img/WelcomePageStep3.png';
import imgbuttonbg from "./img/buttonbg.png";
import imgWebsiteQRCode from './img/websiteQRCode.png';
import { withTranslation } from 'react-i18next';
import './i18n';
import ContactCS from './contactCS'
import  { Analytics } from 'aws-amplify';
import { isMobile } from 'react-device-detect';

class Instructions extends React.Component {
  isOpenTab1 = false;
  isOpenTab2 = false;
  isOpenTab3 = false;
  isScrolled = false;
  //query = new URLSearchParams(this.props.location.search);
  constructor() {
    document.body.style.background = '#B4D9B8';
    Analytics.record({ name: 'OpenInstructions' });
    super();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const currenttab = params.get('currenttab');
    if (currenttab === '1') {
      this.isOpenTab1 = true;
    }
    else if (currenttab === '2') {
      this.isOpenTab2 = true;

    }
    else if (currenttab === '3') {
      this.isOpenTab3 = true;

    }
    window.scrollTo({
      top: 0
    });
    setTimeout(() => {
      this.ScrollToTab();
    }, 500);
  }

  ScrollToTab() {
    //console.log("Updated!");
    if (this.isScrolled) return;
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const currenttab = params.get('currenttab');

    this.isScrolled = true;

    var headerOffset = 0;
    if (currenttab === '1') {
      this.isOpenTab1 = true;
      
      var element = document.getElementById('tabRef1');
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition - headerOffset - 48;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
    else if (currenttab === '2') {

      var element2 = document.getElementById('tabRef2');
      var elementPosition2 = element2.getBoundingClientRect().top;
      var offsetPosition2 = elementPosition2 - headerOffset - 48;

      window.scrollTo({
        top: offsetPosition2,
        behavior: "smooth"
      });
    }
    else if (currenttab === '3') {
      var elementtab3 = document.getElementById('tabRef3');
      var elementPositiontab3 = elementtab3.getBoundingClientRect().top;
      var offsetPositiontab3 = elementPositiontab3 - headerOffset - 48;

      window.scrollTo({
        top: offsetPositiontab3,
        behavior: "smooth"
      });
    }
  }
  Tab1Opened(){
    var offsetPositiontab3 =  document.getElementById('indructionPageHeaderBorder').clientHeight;
    window.scrollTo({
      top: offsetPositiontab3,
      behavior: "smooth"
    });
  }
  Tab1Closed(){
  }
  Tab2Opened(){
    var offsetPositiontab3 = document.getElementById('tabRef1').clientHeight + document.getElementById('indructionPageHeaderBorder').clientHeight;
    window.scrollTo({
      top: offsetPositiontab3,
      behavior: "smooth"
    });
  }
  Tab2Closed(){
  }
  Tab3Opened(){
    var offsetPositiontab3 = document.getElementById('tabRef1').clientHeight + document.getElementById('tabRef2').clientHeight + document.getElementById('indructionPageHeaderBorder').clientHeight;
    window.scrollTo({
      top: offsetPositiontab3,
      behavior: "smooth"
    });
  }
  Tab3Closed(){
  }

  changeLanguage(lng) {

    Analytics.record({ name: 'changeLanguage:'+lng });
    const { i18n } = this.props;
    i18n.changeLanguage(lng);
  };

  componentDidMount()
  {
    this.props.OpenInstructionPage();
  }

  render() {
    const { t } = this.props;
    return (
      <div className="indructionMain">
        <div className="indructionSubMain">
          <div className="indructionHeader">
            <Link className="btninstructions" to="/"><button id="btnHome" className="btninstructions">Home</button></Link>

            <div id="indructionPageHeaderBorder" className="indructionPageHeaderBorder">
              <img className="imgWelcomePageHeader" src={t('Instructions1.InstructionsPageHeaderimg')} alt="" />
            </div>
            <div className="content">

            <div id="tabRef1">
              <Collapsible  onOpen={this.Tab1Opened} onClose={this.Tab1Closed} tabIndex={0} trigger={<div className="CollapsibleText"><img className="imgInstructionsStep1" src={imgInstructionsStep1} alt="" /><a className="imgInstructionsStep1Text">{t('Instructions1.Header')}</a></div>} open={this.isOpenTab1}>
                <div>
                {isMobile && (<div><br></br><div className='InstructionText2'><center>{t('Instructions1.Text')}</center></div><br></br>
                  <img className="imgIntructions1" src={imgIntructions1} alt="" /><br></br>
                  <center>
                  <table>
                    <tbody>
                      <tr>
                        <td className="Instruction1TD1">{t('Instructions1.Text2')}</td>
                        <td className="Instruction1TD2">{t('Instructions1.Text3')}<br></br>{t('Instructions1.Text4')}</td>
                      </tr>
                    </tbody>
                  </table>
                  </center>
                  <br></br><br></br>
                  <div className="btncontainer">
                  <img src={imgbuttonbg} className="imgScanCode" alt="" onClick={()=>this.props.MenuScanCode()}/>
                  
                  <div className="btnText" onClick={()=>this.props.MenuScanCode()}>{t('Instructions1.Button')}</div>
                  </div>
                  <br></br><br></br>
                  </div>)
                }
                {!isMobile && (<div>

                  <div className="DesktopDiv"><br></br><br></br>
                    {t('ScanPage.UserMobileText')}<br></br><br></br><br></br><img src={imgWebsiteQRCode} alt="" /><br></br><br></br><br></br>
                  </div>

                </div>
                )}
                </div>
              </Collapsible>
              </div>
              <div id="tabRef2">
                <Collapsible onOpen={this.Tab2Opened} onClose={this.Tab2Closed}  id="Collapsible2" tabIndex={1} trigger={<div className="CollapsibleText"><img className="imgInstructionsStep2" src={imgInstructionsStep2} alt="" /><a className="imgInstructionsStep2Text">{t('Instructions2.Header')}</a></div>} open={this.isOpenTab2}>
                  <br></br><div className='InstructionText2'>{t('Instructions2.Text')}</div><br></br>
                  <br></br>
                  <ContactCS tabindex={2} /><br></br>
                  <div className="btncontainer"><img src={imgbuttonbg} className="imgBacktoHome" alt="" onClick={()=>{
                        this.props.MenuBackToHome();
                      }
                    }/>
                    <div className="btnText" onClick={()=>{
                        
                        this.props.MenuBackToHome();
                      }
                    }>{t('Instructions2.Button')}</div>
                    </div>
                    <br></br><br></br><br></br>
                </Collapsible>
              </div>
              <div id="tabRef3">
                <Collapsible onOpen={this.Tab3Opened}  onClose={this.Tab3Closed} tabIndex={2} trigger={<div className="CollapsibleText"><img className="imgInstructionsStep3" src={imgInstructionsStep3} alt="" /><a className="imgInstructionsStep3Text">{t('Instructions3.Header')}</a></div>} open={this.isOpenTab3}>
                  <br></br>
                  <table className="Step3Table">
                    <tbody>
                      <tr>
                        <td width="60%">
                          <div className='InstructionText3'>{t('Instructions3.Text')}<a href={t('Instructions3.link')}>{t('Instructions3.Text2')}</a>{t('Instructions3.Text3')}</div>
                  
                        </td>
                      </tr>
                    </tbody>
                    </table><br></br>
                  <ContactCS tabindex={3} /><br></br>
                  <div className="btncontainer"><img src={imgbuttonbg} className="imgBacktoHome" alt=""  onClick={()=>{
                        this.props.MenuBackToHome();
                      }
                    }/>
                    <div className="btnText" onClick={()=>{
                        this.props.MenuBackToHome();
                      }}>{t('Instructions3.Button')}</div>
                    </div>
                    <br></br><br></br><br></br>
                </Collapsible>
                <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default withTranslation()(Instructions);
