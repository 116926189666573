/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "API": {
        "endpoints": [
            {
                "name": "qrcodevalidationapi",
                "endpoint": "https://0yl45a42v3.execute-api.ap-southeast-1.amazonaws.com/prod"
            }
        ]
    }
};


export default awsmobile;
